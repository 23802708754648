import React, { useCallback, Fragment, useContext, useEffect } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { func, object, string, bool } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button } from 'react-bootstrap';
import { getCurrencySymbol, setPriceZeroes } from '../util';
import { CartStore, useGlobalOptions } from '@corratech/context-provider';
import { CollapsibleContainer } from '@corratech/collapsible-container';
import { useDataLayerAction, deepValue } from '@corratech/tag-manager';
import './CostBlock.less';
import { PaypalExpress } from '@corratech/paypal-express';
import { ApplePayButton } from 'ModulesPath/BrainTreeApplePay';
import { TrackInitiateCheckout } from 'ModulesPath/FacebookConversion/TrackInitiateCheckout';

export const CostBlock = props => {
    const [t] = useTranslation();
    const globalOptions = useGlobalOptions();
    const storeConfig = globalOptions.storeConfig || {};
    const includeTaxSubtotal =
        storeConfig.display_subtotal_shopping_cart === '2' ? true : false;
    const history = useHistory();

    const { cartState } = useContext(CartStore);
    const dataLayerAction = useDataLayerAction();

    const callTrackInitiateCheckout = TrackInitiateCheckout();
    const handleClick = useCallback(() => {
        props.closeCart();
        // Track FB Initiate Checkout
        callTrackInitiateCheckout();

        if (props.proceedToCheckout) {
            history.push('/checkout');
        } else {
            history.push('/my-cart');
        }
    }, [props.closeCart, props.proceedToCheckout, history]);

    const totalQty = cartState.cart.items
        ? cartState.cart.items.reduce((qty, item) => qty + item.quantity, 0)
        : 0;

    const getCouponCodeForDiscountLabel = () => {
        if (
            cartState.cart.applied_coupons &&
            cartState.cart.applied_coupons[0]
        ) {
            return `${t('Promo Code')} "${
                cartState.cart.applied_coupons[0].code
            }"`;
        } else {
            let discounts = deepValue(
                cartState,
                ['cart', 'prices', 'discounts'],
                null
            );

            if (discounts && discounts[0]) {
                return `${t('Promo')} "${discounts[0].label}"`;
            }
        }
        return t('Promo Code');
    };

    const getTotalTax = () => {
        const totalTax = cartState.cart.prices.applied_taxes.reduce(
            (accumulator, tax) => accumulator + tax.amount.value,
            0
        );
        return totalTax.toFixed(2);
    };

    useEffect(() => {
        if (props.isOGPresent) {
            document.body.classList.add('disable-klarna');
        } else {
            document.body.classList.remove('disable-klarna');
        }
    }, [props.isOGPresent]);

    const SimpleCostBlock = () => {
        const total = cartState.cart.prices.grand_total;
        return (
            <>
                <span className={'cart-items-count'}>
                    <b>
                        {props.showTotalCount
                            ? totalQty
                            : cartState.cart.items.length}
                    </b>{' '}
                    {cartState.cart.items.length === 1
                        ? t('Item in Cart')
                        : t('Items in Cart')}
                </span>
                <span className={'simple-block-subtotal'}>
                    {t('Cart Subtotal')}:{' '}
                    <b>
                        {getCurrencySymbol(total.currency) +
                            setPriceZeroes(total.value)}
                    </b>
                </span>
            </>
        );
    };

    const DetailedCostBlock = () => {
        const total = cartState.cart.prices.grand_total;

        return (
            <Fragment>
                <div className="row cart-items-count'">
                    <div className="col-left">
                        {cartState.cart.items.length === 1
                            ? t('Item in Cart')
                            : t('Items in Cart')}
                    </div>
                    <div className="col-right">
                        <b>
                            {props.showTotalCount
                                ? totalQty
                                : cartState.cart.items.length}
                        </b>
                    </div>
                </div>
                {includeTaxSubtotal ? (
                    <Fragment>
                        {cartState.cart.prices.subtotal_including_tax ? (
                            <div className={'cart-subtotal'}>
                                <div className={'row'}>
                                    <div className={'col-left'}>
                                        {t('Subtotal')}
                                    </div>
                                    <div
                                        className={
                                            'col-right cart-subtotal-value'
                                        }
                                    >
                                        {getCurrencySymbol(
                                            cartState.cart.prices
                                                .subtotal_including_tax.currency
                                        ) +
                                            setPriceZeroes(
                                                cartState.cart.prices
                                                    .subtotal_including_tax
                                                    .value
                                            )}
                                    </div>
                                </div>
                            </div>
                        ) : null}
                    </Fragment>
                ) : (
                    <Fragment>
                        {cartState.cart.prices.subtotal_excluding_tax ? (
                            <div className={'cart-subtotal'}>
                                <div className={'row'}>
                                    <div className={'col-left'}>
                                        {t('Subtotal')}
                                    </div>
                                    <div
                                        className={
                                            'col-right cart-subtotal-value'
                                        }
                                    >
                                        {getCurrencySymbol(
                                            cartState.cart.prices
                                                .subtotal_excluding_tax.currency
                                        ) +
                                            setPriceZeroes(
                                                cartState.cart.prices
                                                    .subtotal_excluding_tax
                                                    .value
                                            )}
                                    </div>
                                </div>
                            </div>
                        ) : null}
                    </Fragment>
                )}
                {!cartState.cart.is_virtual && (
                    <div className={'price-line cart-sub-total row'}>
                        <div className={'col-left'}>{t('Shipping')} </div>
                        <div className={'col-right'}>
                            {!(
                                cartState.cart.shipping_addresses &&
                                cartState.cart.shipping_addresses[0] &&
                                cartState.cart.shipping_addresses[0]
                                    .selected_shipping_method
                            ) ? (
                                <i>{t('calculated in checkout')}</i>
                            ) : (
                                <span>
                                    {`${cartState.cart.shipping_addresses[0].selected_shipping_method.carrier_title} - `}
                                    {getCurrencySymbol(
                                        cartState.cart.shipping_addresses[0]
                                            .selected_shipping_method.amount
                                            .currency
                                    ) +
                                        setPriceZeroes(
                                            cartState.cart.shipping_addresses[0]
                                                .selected_shipping_method.amount
                                                .value
                                        )}
                                </span>
                            )}
                        </div>
                    </div>
                )}
                <div className={'price-line cart-sub-total row'}>
                    <div className={'col-left'}>{t('VAT 20%')} </div>
                    <div className={'col-right'}>
                        {cartState.cart.prices.applied_taxes.length > 0 ? (
                            <span>
                                {getCurrencySymbol(
                                    cartState.cart.prices.applied_taxes[0]
                                        .amount.currency
                                ) + getTotalTax()}
                            </span>
                        ) : (
                            <i>{t('calculated in checkout')}</i>
                        )}
                    </div>
                </div>

                {(cartState.cart.applied_coupons &&
                    cartState.cart.applied_coupons[0]) ||
                (cartState.cart.prices.discounts &&
                    cartState.cart.prices.discounts[0]) ? (
                    <div className={'cart-discount'}>
                        <div className={'row'}>
                            <div className={'col-left'} data-cs-mask>
                                {getCouponCodeForDiscountLabel()}
                            </div>
                            <div className={'col-right cart-discount-value'}>
                                {'-'}
                                {getCurrencySymbol(
                                    cartState.cart.prices
                                        .subtotal_with_discount_excluding_tax
                                        .currency
                                ) +
                                    (
                                        parseFloat(
                                            cartState.cart.prices
                                                .subtotal_excluding_tax.value
                                        ) -
                                        parseFloat(
                                            cartState.cart.prices
                                                .subtotal_with_discount_excluding_tax
                                                .value
                                        )
                                    ).toFixed(2)}
                            </div>
                        </div>
                    </div>
                ) : null}

                {!props.showCollapsible && (
                    <div className={'price-line cart-grand-total row'}>
                        <div className={'col-left'}>{t('Estimated Total')}</div>
                        <div className={'col-right'}>
                            {getCurrencySymbol(total.currency) +
                                setPriceZeroes(total.value)}
                        </div>
                    </div>
                )}
            </Fragment>
        );
    };

    const CustomCostBlock = () => {
        const total = cartState.cart.prices.grand_total;

        const displayPrice = (price, label, key) => {
            const currency = getCurrencySymbol(
                cartState?.cart?.prices?.subtotal_with_discount_excluding_tax
                    ?.currency
            );
            return (
                <div className={'row'} key={key}>
                    <div className={'col-left'} data-cs-mask>{label}</div>
                    <div className={'col-right cart-discount-value'}>
                        {'-'}
                        {currency + price.toFixed(2)}
                    </div>
                </div>
            );
        };

        const DisplayGiftCardDiscountPrice = () => {
            const discounts = deepValue(
                cartState,
                ['cart', 'prices', 'discounts'],
                null
            );

            return discounts?.map(discount => {
                const price = discount?.amount?.value;

                if (discount?.label.includes('Gift Card')) {
                    const label = discount?.label
                        ? discount?.label
                        : `${t('Gift Cards')}`;
                    return displayPrice(price, label, `giftcard-${discount}`);
                }
            }) || null;
        };

        const DisplayDiscountPrice = () => {
            const separateDiscounts = deepValue(
                cartState,
                ['cart', 'prices', 'separate_discounts'],
                null
            );

            return separateDiscounts?.map((separateDiscount, index) => {
                const price = separateDiscount?.amount?.value;
                const label = separateDiscount?.label;

                if (price && label) {
                    return displayPrice(price, label, `discount-${index}`);
                }
            }) || null;
        };

        const getDiscountPrice = () => {
            return (
                parseFloat(cartState.cart.prices.subtotal_excluding_tax.value) -
                parseFloat(
                    cartState.cart.prices.subtotal_with_discount_excluding_tax
                        .value
                )
            );
        };
        const getSubtotal = () => {
            let subTotal = 0;
            if (includeTaxSubtotal) {
                if (cartState.cart.prices.subtotal_including_tax) {
                    subTotal =
                        cartState.cart.prices.subtotal_including_tax.value;
                }
            } else {
                if (cartState.cart.prices.subtotal_excluding_tax) {
                    subTotal =
                        cartState.cart.prices.subtotal_excluding_tax.value;
                }
            }
            return setPriceZeroes(subTotal);
        };

        return (
            <Fragment>
                {includeTaxSubtotal ? (
                    <Fragment>
                        {cartState.cart.prices.subtotal_including_tax ? (
                            <div className={'cart-subtotal'}>
                                <div className={'row'}>
                                    <div className={'col-left'}>
                                        {t('Subtotal')}
                                    </div>
                                    <div
                                        className={
                                            'col-right cart-subtotal-value'
                                        }
                                    >
                                        {getCurrencySymbol(
                                            cartState.cart.prices
                                                .subtotal_including_tax.currency
                                        ) + getSubtotal()}
                                    </div>
                                </div>
                            </div>
                        ) : null}
                    </Fragment>
                ) : (
                    <Fragment>
                        {cartState.cart.prices.subtotal_excluding_tax ? (
                            <div className={'cart-subtotal'}>
                                <div className={'row'}>
                                    <div className={'col-left'}>
                                        {t('Subtotal')}
                                    </div>
                                    <div
                                        className={
                                            'col-right cart-subtotal-value'
                                        }
                                    >
                                        {getCurrencySymbol(
                                            cartState.cart.prices
                                                .subtotal_excluding_tax.currency
                                        ) + getSubtotal()}
                                    </div>
                                </div>
                            </div>
                        ) : null}
                    </Fragment>
                )}
                {!cartState.cart.is_virtual && (
                    <div className={'price-line cart-sub-total row'}>
                        <div className={'col-left'}>{t('Shipping')} </div>
                        <div className={'col-right'}>
                            {!(
                                cartState.cart.shipping_addresses &&
                                cartState.cart.shipping_addresses[0] &&
                                cartState.cart.shipping_addresses[0]
                                    .selected_shipping_method
                            ) ? (
                                <i>{t('calculated in checkout')}</i>
                            ) : (
                                <span>
                                    {`${cartState.cart.shipping_addresses[0].selected_shipping_method.carrier_title} `}
                                    {getCurrencySymbol(
                                        cartState.cart.shipping_addresses[0]
                                            .selected_shipping_method.amount
                                            .currency
                                    ) +
                                        setPriceZeroes(
                                            cartState.cart.shipping_addresses[0]
                                                .selected_shipping_method.amount
                                                .value
                                        )}
                                </span>
                            )}
                        </div>
                    </div>
                )}

                {cartState?.cart?.prices?.separate_discounts ? (
                    <div className={'cart-discount'}>
                        <DisplayDiscountPrice/>
                        <DisplayGiftCardDiscountPrice/>
                    </div>
                ) : null}
                <div className={'price-line cart-grand-total row'}>
                    <div className={'col-left'}>{t('Estimated Total')}</div>
                    <div className={'col-right'}>
                        {getCurrencySymbol(total.currency) +
                            setPriceZeroes(total.value)}
                    </div>
                </div>
            </Fragment>
        );
    };

    const isPaymentMethodAvailable = methodCode => {
        return (
            cartState.cart &&
            cartState.cart.available_payment_methods &&
            cartState.cart.available_payment_methods.findIndex(
                x => x.code === methodCode
            ) > -1
        );
    };

    const CartLinkSection = () => (
        <div className={'cart-link-wrapper'}>
            <Button size="lg" variant="secondary" onClick={handleClick} block>
                {t('Checkout')}
            </Button>
        </div>
    );

    if (
        cartState.cart &&
        cartState.cart.prices &&
        cartState.cart.prices.grand_total
    ) {
        const total = cartState.cart.prices.grand_total;
        return (
            <div
                className={'cost-block ' + (props.className || '')}
                css={props.css}
            >
                {props.costBlockVariant === 'simple' && <SimpleCostBlock />}
                {props.costBlockVariant === 'detailed' && <DetailedCostBlock />}
                {props.costBlockVariant === 'custom' && <CustomCostBlock />}
                {props.costBlockVariant === 'detailed-collapsible' && (
                    <CollapsibleContainer
                        title={
                            <div className={'cart-grand-total'}>
                                {t('Estimated Total')}:{' '}
                                {getCurrencySymbol(total.currency) +
                                    setPriceZeroes(total.value)}
                            </div>
                        }
                    >
                        <DetailedCostBlock />
                    </CollapsibleContainer>
                )}

                <CartLinkSection />
                {storeConfig.paypal_express_active &&
                    storeConfig.paypal_visible_on_cart &&
                    !props.isOGPresent && (
                        <PaypalExpress
                            isIncontext={storeConfig.paypal_incontext_mode}
                            merchantId={storeConfig.paypal_account_id}
                            isSandbox={
                                storeConfig.paypal_account_is_sandbox_mode
                            }
                            returnUrl={'checkout/paypal/returnfromcart'}
                        />
                    )}
                {!props.isOGPresent &&
                    !!cartState?.cart?.prices?.grand_total?.value &&
                    isPaymentMethodAvailable('braintree_applepay') && (
                        <ApplePayButton />
                    )}
            </div>
        );
    } else {
        return null;
    }
};

CostBlock.propTypes = {
    cart: object.isRequired,
    className: string,
    closeCart: func.isRequired,
    css: object,
    costBlockVariant: string,
    proceedToCheckout: bool,
    showTotalCount: bool
};

CostBlock.defaultProps = {
    costBlockVariant: 'detailed-collapsible'
};
